<template>
  <v-container>
    <ModalPix v-if="showModalPix" />
    <ModalDigitalAccount v-if="showModal" />
    <v-col v-show="!getLoading" class="pa-0">
      <v-row>
        <v-col v-if="showAccount" md="6" cols="12">
          <Card
            :value="service.formatCurrency(getDigitalBalance)"
            title="Saldo Conta Digital"
            class="digital-title pb-0"
          />
        </v-col>
        <v-col
          class="infoAccount"
          cols="12 pt-0 pb-0 ml-1"
          v-if="$vuetify.breakpoint.smAndDown && showAccount"
        >
          <v-row justify="start">
            <v-col cols="5">
              <span>Agência:</span>
              <span class="agency ml-2">{{ getDigitalAccount.branch }}</span>
            </v-col>
            <v-col cols="6">
              <span>Conta:</span>
              <span class="account ml-2">{{ getDigitalAccount.account }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" cols="12" v-if="!isInternal">
          <Card
            :value="service.formatCurrency(getBalance)"
            title="Saldo Maquininha"
            titleStyle="danger"
            class="machine-title"
          />
        </v-col>
        <v-col md="12" v-if="!getDigitalAccount" />
      </v-row>
      <v-row class="infoAccount-row">
        <v-col
          class="infoAccount pl-8 pl-md-0"
          md="6"
          cols="6"
          v-if="$vuetify.breakpoint.mdAndUp && showAccount"
        >
          <v-row class="info-account-align">
            <v-col md="1"></v-col>
            <v-col md="4" cols="12">
              <span>Agência:</span>
              <span class="agency ml-2">{{ getDigitalAccount.branch }}</span>
            </v-col>
            <v-col md="4" cols="12" class="pr-0">
              <span>Conta:</span>
              <span class="account ml-2">{{ getDigitalAccount.account }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="12" v-if="getRole === 'seller'" />
        <v-col v-if="getRole == 'supplier'" class="infoAccountValue" md="4">
          <v-row class="infoAccountValue-align" v-if="!isInternal">
            <v-col class="solicitedValue" md="6" cols="6">
              <span>
                Valor Solicitado:
              </span>
            </v-col>
            <v-col md="6" cols="6 pl-0">
              <v-text-field
                class="valueInputInfoAccount"
                :label="service.formatCurrency(getTransferBalance)"
                disabled
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="dividerTable"></v-divider>
      <v-col cols="12" md="5" class="ml-2 " v-if="showAccount">
        <v-row
          :justify="getDigitalAccount && !isInternal ? 'center' : 'start'"
          class="buttons mt-5 mt-md-0"
        >
          <v-col cols="6" class="pa-0" v-if="hasPermission">
            <v-btn
              class="selectTable"
              color="#00C1C1"
              @click="selectDigital"
              rounded
              :outlined="digital"
              dark
              >Conta Digital</v-btn
            >
          </v-col>
          <v-col cols="6" class="pa-0" v-if="!isInternal">
            <v-btn
              class="selectTable"
              color="#D70472"
              @click="selectMachine"
              rounded
              :outlined="machine"
              dark
              >Maquininha</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <span class="titleTable mt-10 ml-5">Últimos lançamentos</span>
      </v-row>
      <v-row>
        <v-data-table
          hide-default-footer
          :headers="!machine ? headersMachine : headersDigital"
          :items="dataTableItems"
          :mobile-breakpoint="600"
          class="dataTableContent ma-3"
        >
          <template v-slot:item.status="{ item }">
            <span :class="statusColor[item.status]">{{ item.statusText }}</span>
          </template>
          <template v-slot:item.value="{ item }">
            <span v-if="item.typeSymbol === '-'" class="debit">{{ `-${item.value}` }}</span>
            <span v-if="item.typeSymbol === '+'" class="credit">{{ `+${item.value}` }}</span>
            <span v-if="item.type === 'D'" class="debit">{{ `-${item.value}` }}</span>
            <span v-if="item.type === 'C'" class="credit">{{ `+${item.value}` }}</span>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Card from "../../../components/Card.vue";
import ModalDigitalAccount from "../../commons/ModalDigitalAccount.vue";
import ModalPix from "../../commons/ModalPix.vue";
import service from "../../../service";
import dataHome from "./consts";
import roles from "../../../config/permissions";

export default {
  components: {
    Card,
    ModalDigitalAccount,
    ModalPix
  },
  data() {
    return {
      machine: true,
      digital: false,
      showModal: false,
      showModalPix: false,
      service,
      dialog: true,
      hasPermission: false,
      statusColor: {
        0: "grey--text",
        1: "teal--text",
        2: "grey--text",
        3: "red--text",
        null: "grey--text"
      }
    };
  },
  methods: {
    ...mapActions({
      setBalanceDigital: "DigitalAccount/setBalance",
      setStatement: "DigitalAccount/consultStatement",
      setRequests: "Request/setRequests",
      setBalance: "Request/setBalance",
      setTransferBalance: "Request/setTransferBalance",
      getAllPixKeys: "Pix/getAllPixKeys",
      checkStatusModal: "Pix/checkStatusModal",
      hasRole: "User/hasRole"
    }),
    ...mapMutations({
      setKeyExists: "Pix/SET_KEY_EXISTS"
    }),

    selectDigital() {
      if (this.digital === true) {
        this.digital = false;
        this.machine = true;
      }
    },
    selectMachine() {
      if (this.machine === true) {
        this.digital = true;
        this.machine = false;
      }
    },
    formatValue(value, symbol) {
      const val = symbol === "-" ? -value : value;
      return this.service.formatCurrency(val);
    },
    async consultKey() {
      await this.checkStatusModal();
      if (this.getModalStatus) {
        await this.getAllPixKeys({
          page: 1,
          perPage: 20
        });
        if (
          this.getDataKeys.items[0].alias === null ||
          this.getDataKeys.items[0].alias.length === 0
        ) {
          this.setKeyExists(false);
        } else {
          this.setKeyExists(true);
        }
      }
    },
    async initialize() {
      await this.setBalance();
      await this.setTransferBalance();
      await this.setRequests({ perPage: 10 });
      if (this.getDigitalAccount && !this.accountIsClosed && this.hasPermission) {
        await this.setBalanceDigital();
        await this.setStatement({ period: {} });
      }
    }
  },
  computed: {
    ...mapGetters({
      getDigitalBalance: "DigitalAccount/getBalance",
      getStatement: "DigitalAccount/getConsultStatement",
      getDigitalAccount: "DigitalAccount/getAccount",
      showPasswordModal: "DigitalAccount/getShowPasswordModal",
      showAccount: "DigitalAccount/getShowAccount",
      getBalance: "Request/getBalance",
      getRequests: "Request/getRequests",
      getTransferBalance: "Request/getTransferBalance",
      getRole: "Auth/getRole",
      isInternal: "Auth/isInternal",
      getKeyExists: "Pix/getKeyExists",
      getModalStatus: "Pix/getModalStatus",
      getDataKeys: "Pix/getAllDataKeys",
      showPixModal: "Pix/getShowPixModal",
      getLoading: "Loading/getLoading"
    }),
    dataTableItems() {
      if (!this.machine) {
        return this.getRequests.map(el => ({
          createDate: this.$moment(el.createDate).format("DD/MM/YYYY"),
          externalId: el.externalId,
          document: this.service.maskToDocument(el.seller.document),
          seller: `${el.seller.name.substr(0, 20)}`,
          sellerName: el.seller.name,
          value: this.formatValue(el.value, el.typeSymbol),
          typeSymbol: el.typeSymbol,
          status: el.status.id,
          statusText: el.status.statusText
        }));
      }

      const sortedData = this.service.dataSort(this.getStatement, "entryDate").slice(0, 9);

      return sortedData.map(el => ({
        date: this.$moment(el.entryDate).format("DD/MM/YYYY"),
        transictionId: el.transictionId,
        description: el.description,
        value: this.service.formatCurrency(el.amount),
        type: el.type
      }));
    },
    headersMachine() {
      return dataHome.headersMachine;
    },
    headersDigital() {
      return dataHome.headersDigitalAccount;
    }
  },
  async created() {
    this.hasPermission = await this.hasRole(roles.CUSTOMER_WALLET_INSTANT_PAYMENT_ALIAS_WRITE.role);
    await this.consultKey();
    await this.initialize();
    if (!this.hasPermission) this.selectMachine();
    if (!this.showAccount || !this.hasPermission) this.machine = false;
    if (!this.getDigitalAccount.hasPassword && this.showPasswordModal && this.showAccount) {
      this.showModal = true;
    }
    if (
      !this.getKeyExists &&
      this.getDigitalAccount &&
      this.getModalStatus &&
      this.getModalStatus.showAliasModal === true &&
      this.showPixModal
    ) {
      this.showModalPix = true;
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/styles/digitalAccount/home.scss";
@import "~@/assets/styles/supplier/home.scss";
</style>
