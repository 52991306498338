<template>
  <v-row class="rowDialog">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="480"
      class="dialog"
    >
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="modal-title pb-0">
            Novidade!
          </v-col>
          <v-col class="icon pb-1" cols="1">
            <v-icon @click="closeModal" class="close-icon">
              mdi-window-close
            </v-icon>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>
        </v-card-title>
        <v-card-text class="modal-form mt-2 mt-md-0 pa-0 pa-md-2">
          <v-row justify="center" class="success-text">
            <v-col cols="12" md="11" class="mt-0">
              <v-row justify="center">
                <v-col align="center" cols="12" class="pa-6 pt-0">
                  <img src="@/assets/pix.png" alt="" />
                </v-col>
                <v-col cols="12" class="mt-0" align="center">
                  <span class="title-welcome">Já ouviu falar do Pix?</span>
                  <p class="text-welcome mt-5 mb-0">
                    Conheça o Pix! Novo jeito de
                  </p>
                  <p class="text-welcome mb-0">
                    pagar que vai facilitar ainda
                  </p>
                  <p class="text-welcome mb-0">
                    mais a sua rotina.
                  </p>
                </v-col>
                <v-col cols="12" align="center" class="pa-0 pt-10 pt-md-0">
                  <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
                  <v-col md="7" cols="12" class="button-account pl-5 pr-5">
                    <v-btn
                      class="btn-reset"
                      @click="pushToPix"
                      rounded
                      color="#D70472"
                      block
                      :x-large="$vuetify.breakpoint.smAndDown"
                      >Saiba mais</v-btn
                    >
                  </v-col>
                  <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
                  <v-col
                    md="7"
                    cols="12"
                    class="button-back pl-5 pr-5"
                    v-if="$vuetify.breakpoint.smAndDown"
                  >
                    <v-btn
                      class="btn-reset"
                      @click="stopModal"
                      rounded
                      color="#8C8C8C"
                      block
                      x-large
                      outlined
                      >Não desejo mais ver isso</v-btn
                    >
                  </v-col>
                </v-col>
                <v-col
                  align="center"
                  @click="stopModal"
                  class="back-link pt-0"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  Não desejo mais ver isso
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      showModal: true,
      dialog: true
    };
  },
  computed: {
    ...mapGetters({}),
    role() {
      return this.$store.getters["Auth/getRole"];
    }
  },
  methods: {
    ...mapActions({
      updateStatusModal: "Pix/updateStatusModal"
    }),
    ...mapMutations({
      setShowPixModal: "Pix/SET_SHOW_PIX_MODAL"
    }),
    async closeModal() {
      this.dialog = false;
      this.showModal = false;
      this.setShowPixModal(false);
    },
    pushToPix() {
      this.closeModal();
      this.$router.push({ path: `/customer/digitalAccount/pix` });
    },
    async stopModal() {
      await this.updateStatusModal({
        showAliasModal: false
      });
      this.closeModal();
    }
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/commons/modalHomePage.scss";
</style>
