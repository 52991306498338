
export default {
  headersDigitalAccount: [
    {
      text: 'Data',
      align: 'center',
      filterable: false,
      value: 'date',
    },
    {
      text: 'Descrição',
      align: 'center',
      filterable: false,
      value: 'description',
    },
    {
      text: 'Valor',
      align: 'center',
      filter: false,
      value: 'value',
    },
  ],

  headersMachine: [
    {
      text: 'Data da Solicitação',
      align: 'center',
      filterable: false,
      value: 'createDate',
    },
    {
      text: 'Pedido',
      align: 'center',
      filterable: false,
      value: 'externalId',
    },
    {
      text: 'CNPJ',
      align: 'center',
      filter: false,
      value: 'document',
    },
    {
      text: 'Lojista',
      align: 'center',
      filterable: false,
      value: 'seller',
    },
    {
      text: 'Valor',
      align: 'center',
      filterable: false,
      value: 'value',
    },
    {
      text: 'Status',
      align: 'center',
      filterable: false,
      value: 'status',
    },
  ],
};
